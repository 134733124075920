import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { CookstoveProvider } from 'contexts/CookstoveContext';


// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const Dashboard = Loadable(lazy(() => import('views//consumer/dashboard')));
const Analytics = Loadable(lazy(() => import('views//consumer/analytics')));
const Cookstoves = Loadable(lazy(() => import('views//consumer/cookstoves')));
const CreateTicket = Loadable(lazy(() => import('views/consumer/support/create')));
const MyTickets = Loadable(lazy(() => import('views/consumer/support/list')));
const TicketOverview = Loadable(lazy(() => import('views/consumer/support/view')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <CookstoveProvider>
                <MainLayout />
            </CookstoveProvider>
        </AuthGuard>
    ),
    children: [
        {
            path: '/sample-page',
            element: <SamplePage />
        },
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/analytics',
            element: <Analytics />
        },
        {
            path: '/cookstoves',
            element: <Cookstoves />
        },
        {
            path: '/tickets/create',
            element: <CreateTicket />
        },
        {
            path: '/tickets/list',
            element: <MyTickets />
        },
        {
            path: '/tickets/view/:id',
            element: <TicketOverview />
        }
    ]
};

export default MainRoutes;
